import React from "react";
import { useTranslation } from "react-i18next";

import roomImg from "../../assets/images/passangerData/room.png";
import StringInput from "../Inputs/StringInput";
import { PASSENGER_KEYS } from "./PassengersData";
import "./PassengerData.scss";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import Select from "../Inputs/Select";
import DropdownDateInput from "../Inputs/DropdownDateInput";
import { KIDS_AGES, INFANTS, KIDS, ADULTS } from "../../contexts/SearchContext";
import DatePicker from "antd/lib/date-picker";

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.locale("en");
dayjs.locale("vi");

const PassengerData = ({
  passengerInfo,
  setPassengerInfo,
  roomName,
  roomIndex,
  key,
  allDisabled,
  returnDate,
}) => {
  // Translator
  const { t } = useTranslation();
  const inputSyles = {
    [PASSENGER_KEYS.FIRSTNAME]: {
      width: 150,
    },
    [PASSENGER_KEYS.CELL]: {
      width: 120,
    },
    [PASSENGER_KEYS.EMAIL]: {
      width: 200,
    },
  };

  const getPassenger = (paxType, innerIndex) => {
    // console.log("paxType", paxType);
    return passengerInfo[paxType][innerIndex];
  };

  const renderInput = (id, key, innerIndex, paxType, disabled = false) => {
    return (
      <StringInput
        id={id}
        // style={{
        //   marginRight: 15,
        //   justifyContent: "space-between",
        //   ...(inputSyles[key] || {}),
        // }}
        width={inputSyles[key]?.width}
        title={t(`completepassengers.${key}`)}
        value={getPassenger(paxType, innerIndex)[key]}
        onValueChange={(newVal) =>
          handleChange(innerIndex, paxType, key, newVal)
        }
        disabled={disabled || allDisabled}
        hideX
      />
    );
  };

  const renderTitle = (key, innerIndex, paxType, disabled = false) => {
    const optionsAdult = [
      { name: "MR", value: "MR" },
      { name: "MRS", value: "MRS" },
      { name: "MS", value: "MS" },
    ];
    const optionsInfant = [{ name: "INFANT", value: "infant" }];
    const optionsKids = [{ name: "KIDS", value: "kids" }];
    const option =
      paxType == ADULTS
        ? optionsAdult
        : paxType == KIDS
          ? optionsKids
          : optionsInfant;
    return (
      <Select
        options={option}
        style={{ width: 70 }}
        title={key ? t(`completepassengers.${key}`) : ""}
        placeholder={getPassenger(paxType, innerIndex)[key] || option[0].value}
        onChange={(e) => handleChange(innerIndex, paxType, key, e.target.value)}
        disabled={disabled || allDisabled}
        hideX
      />
    );
  };

  const dateFormat = "DD/MM/YYYY";

  const renderDatePickerInput = (
    key,
    innerIndex,
    paxType,
    minAge,
    maxAge,
    disabled = false
  ) => {
    const value = getPassenger(paxType, innerIndex)[key];
    let dateValue = value || undefined;

    const dayjsMinDate = returnDate
      ? dayjs(returnDate, "DD/MM/YYYY").subtract(maxAge, "year")
      : dayjs().subtract(maxAge, "year");
    const dayjsMaxDate = returnDate
      ? dayjs(returnDate, "DD/MM/YYYY").subtract(minAge, "year")
      : dayjs().subtract(minAge, "year");

    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div>{t(`completepassengers.${key}`)}</div>
        <DatePicker
          key={key + innerIndex}
          title={t(`completepassengers.${key}`)}
          picker="date"
          disabled={disabled}
          minDate={dayjsMinDate}
          maxDate={dayjsMaxDate}
          defaultValue={dateValue ? dayjs(dateValue, dateFormat) : undefined}
          onChange={(dates, dateStrings) => {
            handleChange(innerIndex, paxType, key, dateStrings);
            dateValue = dateStrings || undefined;
          }}
          style={{ width: 150 }}
          format={dateFormat}
        />
      </div>
    );
  };

  function handleChange(innerIndex, paxType, key, value) {
    let tempPassengerInfo = JSON.parse(JSON.stringify(passengerInfo));
    tempPassengerInfo[paxType][innerIndex][key] = value;
    setPassengerInfo(tempPassengerInfo);
  }

  const renderKids = () => {
    return passengerInfo?.kids?.map((_, kidInnerIndex) => (
      <div className={"passenger-container"}>
        <label className="roomPassInfo-passenger-info-title">
          {t("completepassengers.kidpassangernum")} {kidInnerIndex + 1}
        </label>

        <div
          className={
            "passenger-inputs-container tw-flex tw-flex-col lg:tw-flex-row"
          }
        >
          {renderInput(
            "firstname",
            PASSENGER_KEYS.FIRSTNAME,
            kidInnerIndex,
            KIDS
          )}

          {renderInput(
            "lastname",
            PASSENGER_KEYS.LASTNAME,
            kidInnerIndex,
            KIDS
          )}

          {renderDatePickerInput(
            PASSENGER_KEYS.AGE,
            kidInnerIndex,
            KIDS,
            2,
            12
          )}
        </div>
      </div>
    ));
  };
  const renderInfants = () => {
    return passengerInfo?.infants.map((_, infantInnerIndex) => (
      <div className={"passenger-container"}>
        <label className="roomPassInfo-passenger-info-title">
          {t("completepassengers.infantpassangernum")} {infantInnerIndex + 1}
        </label>

        <div
          className={
            "passenger-inputs-container tw-flex tw-flex-col lg:tw-flex-row"
          }
        >
          {renderInput(
            "firstname",
            PASSENGER_KEYS.FIRSTNAME,
            infantInnerIndex,
            INFANTS
          )}
          {renderInput(
            "lastname",
            PASSENGER_KEYS.LASTNAME,
            infantInnerIndex,
            INFANTS
          )}
          {renderDatePickerInput(
            PASSENGER_KEYS.AGE,
            infantInnerIndex,
            INFANTS,
            0,
            2
          )}
        </div>
      </div>
    ));
  };

  const renderPassengers = () => {
    // return null;
    return passengerInfo?.adults.map((_, adultInnerIndex) => (
      <div className={"passenger-container"}>
        <label className="roomPassInfo-passenger-info-title">
          {t("completepassengers.passangernum")} {adultInnerIndex + 1}
        </label>
        <div
          className={
            "passenger-inputs-container tw-flex tw-flex-col lg:tw-flex-row"
          }
        >
          {renderTitle(null, adultInnerIndex, ADULTS)}
          {renderInput(
            "firstname",
            PASSENGER_KEYS.FIRSTNAME,
            adultInnerIndex,
            ADULTS
          )}
          {renderInput(
            "lastname",
            PASSENGER_KEYS.LASTNAME,
            adultInnerIndex,
            ADULTS
          )}
          {renderInput("phone", PASSENGER_KEYS.CELL, adultInnerIndex, ADULTS)}
          {renderInput("email", PASSENGER_KEYS.EMAIL, adultInnerIndex, ADULTS)}
          {renderDatePickerInput(
            PASSENGER_KEYS.AGE,
            adultInnerIndex,
            ADULTS,
            12,
            100
          )}
        </div>
      </div>
    ));
  };

  return (
    <div
      className="roomPassInfo-component"
      style={{ paddingBottom: 0, paddingTop: 0 }}
      key={`passenger-${key}`}
    >
      <div className="roomPassInfo-component-item">
        {/* Room Header */}
        <div className="roomPassInfo-main-title-wrapper">
          <div className="roomPassInfo-title-img-wrapper">
            <img className="roomPassInfo-title-img" src={roomImg} alt="Room" />
          </div>

          <div className="roomPassInfo-hotel-name-wrapper">
            <label className="roomPassInfo-hotel-name">{roomName}</label>
          </div>
        </div>

        {/* Title */}
        <div className="roomPassInfo-title-wrapper">
          <div className="roomPassInfo-border"></div>
          <label className="roomPassInfo-title">
            {t("completepassengers.room")} {roomIndex + 1}
          </label>
          <div className="roomPassInfo-border"></div>
        </div>

        {renderPassengers()}
        {renderKids()}
        {renderInfants()}
      </div>
    </div>
  );
};

export default PassengerData;
