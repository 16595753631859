import React from "react";
import "./Select.scss";

export const Select = ({
  title,
  options = [],
  placeholder,
  onChange,
  className,
  ...props
}) => {
  return (
    <div className={"select-input-container"}>
      <select className="input" onChange={onChange} {...props}>
        <option value="" disabled selected>
          {placeholder}
        </option>
        {options.map((p) => (
          <option key={p.value} value={p.value}>
            {p.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
